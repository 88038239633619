define("discourse/plugins/discourse-token-gate/discourse/services/wallet-connect", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/lib/load-script"], function (_exports, _service, _ajax, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WalletConnect extends _service.default {
    connector;
    async connect() {
      await this._loadScripts();
      this.connector = new window.WalletConnect.default({
        bridge: "https://bridge.walletconnect.org",
        qrcodeModal: window.WalletConnectQRCodeModal.default
      });
      if (this.connector.connected) {
        // Always start from a new session
        await this.connector.killSession();
      }
      this.connector.createSession();

      // Subscribe to connection events
      this.connector.on("connect", (error, payload) => {
        if (error) {
          throw error;
        }

        // Get provided accounts and chainId
        const {
          accounts
        } = payload.params[0];
        this.verify(accounts[0]);
      });
    }
    async verify(account) {
      const {
        message
      } = await (0, _ajax.ajax)("/web3/signing_message", {
        method: "PUT"
      });
      const signature = await this.connector.signPersonalMessage([message, account]);
      const result = await (0, _ajax.ajax)("/web3/verify_public_key", {
        type: "PUT",
        data: {
          signature,
          public_key: account
        }
      });
      this.currentUser.setProperties({
        web3_public_key: result.public_key,
        web3_has_token: result.has_token
      });
    }
    async _loadScripts() {
      await (0, _loadScript.default)("/plugins/discourse-token-gate/wallet_connect/1.7.4/client.min.js", {
        css: false
      });
      await (0, _loadScript.default)("/plugins/discourse-token-gate/wallet_connect/1.7.4/qrcode-modal.min.js", {
        css: false
      });
    }
  }
  _exports.default = WalletConnect;
});