define("discourse/plugins/discourse-token-gate/discourse/services/meta-mask", ["exports", "@ember/service", "discourse/lib/ajax", "discourse-common/config/environment"], function (_exports, _service, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MetaMask extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get metaMaskProvider() {
      return window.ethereum;
    }
    get installed() {
      return (0, _environment.isTesting)() || this.metaMaskProvider?.isMetaMask;
    }
    async verify() {
      if (!this.installed) {
        this.dialog.alert("MetaMask is not installed");
        return;
      }
      try {
        const [account] = await this.metaMaskProvider.request({
          method: "eth_requestAccounts"
        });
        const {
          message
        } = await (0, _ajax.ajax)("/web3/signing_message", {
          type: "PUT"
        });
        const signature = await this.metaMaskProvider.request({
          method: "personal_sign",
          params: [message, account]
        });
        const verificationResult = await (0, _ajax.ajax)("/web3/verify_public_key", {
          type: "PUT",
          data: {
            signature,
            public_key: account
          }
        });
        this.currentUser.setProperties({
          web3_public_key: verificationResult.public_key,
          web3_has_token: verificationResult.has_token
        });
        return verificationResult;
      } catch (error) {
        this.dialog.alert(`Verification failed: ${error.message}`);
        throw error;
      }
    }
  }
  _exports.default = MetaMask;
});