define("discourse/plugins/discourse-token-gate/discourse/routes/preferences-wallets", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/routes/restricted-user"], function (_exports, _service, _ajax, _restrictedUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _restrictedUser.default.extend({
    currentUser: (0, _service.service)(),
    async model() {
      const userId = this.modelFor("user").id;
      if (userId === this.currentUser.id) {
        return {
          publicKey: this.currentUser.web3_public_key,
          hasToken: this.currentUser.web3_has_token
        };
      }
      const response = await (0, _ajax.ajax)(`/web3/user_public_key/${userId}`);
      return {
        publicKey: response.public_key,
        hasToken: response.has_token
      };
    }
  });
});