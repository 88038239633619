define("discourse/plugins/discourse-token-gate/discourse/controllers/preferences-wallets", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "I18n"], function (_exports, _controller, _object, _computed, _service, _ajax, _ajaxError, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    walletConnect: (0, _service.service)(),
    metaMask: (0, _service.service)(),
    dialog: (0, _service.service)(),
    isUpdating: null,
    isMetaMaskInstalled: (0, _computed.readOnly)("metaMask.installed"),
    tokenName: (0, _computed.readOnly)("siteSettings.token_name"),
    requiredMinTokenBalance: (0, _computed.readOnly)("siteSettings.required_token_balance"),
    verifyConnectedMetaMaskAccount() {
      this.set("isUpdating", true);
      return this.metaMask.verify().finally(() => {
        this.set("isUpdating", false);
      });
    },
    verifyConnectedWalletConnectAccount() {
      this.walletConnect.connect();
    },
    removeWeb3Wallet() {
      this.dialog.yesNoConfirm({
        title: _I18n.default.t("web3.preferences.remove_wallet_confirmation"),
        didConfirm: () => {
          this.set("isUpdating", true);
          (0, _ajax.ajax)("/web3/public_keys", {
            type: "DELETE"
          }).then(() => {
            if (this.isDestroying || this.isDestroyed) {
              return;
            }
            this.currentUser.setProperties({
              web3_public_key: null,
              web3_has_token: null
            });
          }).catch(_ajaxError.popupAjaxError).finally(() => this.set("isUpdating", false));
        }
      });
    }
  }, [["method", "verifyConnectedMetaMaskAccount", [_object.action]], ["method", "verifyConnectedWalletConnectAccount", [_object.action]], ["method", "removeWeb3Wallet", [_object.action]]]));
});